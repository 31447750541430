@import '../node_modules/react-dat-gui/dist/index.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.abs {
  position: absolute;
  top: 0;
  left: 0;
}

.camera-container {
  display: block !important;
  justify-content: normal !important;
  align-items: normal !important;
}

circle, rect {
  transition: all 300ms ease-in-out;
}

h1 {
  font-family: 'JetBrains Mono';
  font-weight: bold;
}

.react-dat-gui {
  position: absolute;
  right: 0;
  top: 0;
  left: auto;
}

/* .face-part * {
  transition: all 700ms ease-in-out;
} */

/* .svg-class-name * {
  display: none;
}

.svg-class-name svg *:target {
  display: block;
} */
